.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgb(230, 221, 221);
  border-radius: 50%;
  border-radius: 50%;
  border-top-color: #02405e;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
