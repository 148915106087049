@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}
@mixin for-ipad-up {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}
@mixin for-tablet-up {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
@mixin for-header-desktop {
  @media (min-width: 761px) {
    @content;
  }
}
@mixin for-tablet-portrait-view-only {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}
@mixin for-smaller-desktops-view-only {
  @media (min-width: 1260px) and (max-width: 1440px) {
    @content;
  }
}
