/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind utilities;
@import 'styles/developer-portal-styles/base';
@import 'styles/developer-portal-styles/media-queries';
@import 'styles/developer-portal-styles/reboot';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  background-color: #f6f6f6;
  height: 100%;
  overflow-y: scroll;

  @include for-phone-only {
    margin: 0 auto;
  }
}

.business-type {
  ul {
    list-style-type: disc;
    padding-left: 3rem; 
  }
}

// target iOS only
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  // html body {
  //   padding-top: 40px;
  //  }
}
